/**
 * @license
 * Copyright 2020 Google Inc. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * =============================================================================
 */

export const PART_CHANNELS: string[] = [
  'left_face',
  'right_face',
  'left_upper_arm_front',
  'left_upper_arm_back',
  'right_upper_arm_front',
  'right_upper_arm_back',
  'left_lower_arm_front',
  'left_lower_arm_back',
  'right_lower_arm_front',
  'right_lower_arm_back',
  'left_hand',
  'right_hand',
  'torso_front',
  'torso_back',
  'left_upper_leg_front',
  'left_upper_leg_back',
  'right_upper_leg_front',
  'right_upper_leg_back',
  'left_lower_leg_front',
  'left_lower_leg_back',
  'right_lower_leg_front',
  'right_lower_leg_back',
  'left_feet',
  'right_feet'
];
